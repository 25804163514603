* {
    box-sizing: border-box;
}

:root {
    --first-color: #FFFFFF;
    --second-color: #03045E;
}

nav {
    background-color: var(--second-color);
    font-family: 'Montserrat', sans-serif;
}

.navname {
    vertical-align: middle;
}

body {
    background-color: var(--first-color);
    color: #FFFFFF;
    font-family: 'Montserrat', sans-serif;
}

header {
    text-align: center;
}

p {
    line-height: 1.8;
}

h1, h2 {
    color: var(--second-color);
    font-family: 'Montserrat', sans-serif;
}

h3 {
    color: var(--second-color);
    font-family: 'Georama', sans-serif;
}

main {
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

.material-icons {
    vertical-align: middle;
}

footer {
    background-color: var(--second-color);
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 100%;
}

footer p {
    text-align: center;
    margin: 0;
}

footer a {
    color: white;
}