* {
  padding: 0;
}

html {
  height: 100%;
}

body {
  height: calc(100% + 60px);
  margin: 62px 0;
}

main {
  margin: 0 14px;
  width: calc(100% - 28px);
}

h1 {
  padding-top: 1rem;
  font-weight: 700;
  font-size: 41px;
}

h2 {
  font-size: 24px;
  font-weight: 400;
  margin: 0;
}

h2 span {
  font-weight: 700;
  color: #EF4444;
}

a {
  text-decoration: none;
  color: #EF4444;
}

p {
  margin: 0;
}

@media  (min-width: 992px) {
  body {
      height: calc(100% - 62px);
  }

  main {
      height: calc(100% - 40px);
      margin: 0 2rem;
      width: calc(100% - 4rem);
  }

  h1 {
      padding: 0;
      font-size: 3.5rem;
      margin-top: 40px;
  }

  h2 {
      font-size: 28px;
  }
}